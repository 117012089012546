import {
    asset,
    defineComponent,
    reactive,
    computed,
    watch,
    useValidate,
    required,
    helpers,
    selectThemeOverrides,
    inputThemeOverrides,
    buttonThemeOverrides,
    typographyThemeOverrides,
    railStyle,
    fetchSearchResultsApi,
    getServiceProfileLink,
    getPackageProfileLink,
    getItemProfileLink,
    fetchPopularsApi,
    debounce,
} from "@jsRoot/search/ddl";
export default defineComponent({
    name: "Search",
    props: {},
    setup(props) {
        const state = reactive({
            search: null,
            results: {},
            populars: {},
            focus: false,
            submitting: false,
        });
        const rules = {
            search: {
                required,
                alpha: (value) =>
                    !helpers.req(value) || helpers.regex(/^[a-zA-Z0-9_-\s]*$/),
            },
        };
        const vd = useValidate(rules, state);
        const updateSearch = debounce((e) => {
            e.preventDefault();
            e.stopPropagation();
            state.search = e.target.value;
        }, 1000);
        watch(
            () => state.search,
            () => {
                if (!!state.search) {
                    fetchResults();
                } else {
                    state.results = [];
                }
            }
        );
        const searchResults = computed(() => state.results);
        const populars = computed(() => state.populars);
        const fetchResults = async () => {
            state.results = [];
            const fd = new FormData();
            fd.append("search", state.search);
            await axios({
                method: "post",
                url: fetchSearchResultsApi,
                data: fd,
            })
                .then((res) => {
                    if (
                        !!res.data.data &&
                        res.data.hasOwnProperty("data") &&
                        res.data.data.hasOwnProperty("results")
                    ) {
                        state.results = res.data.data.results;
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        };
        const fetchPopulars = async () => {
            state.populars = [];
            await axios({ method: "post", url: fetchPopularsApi, data: {} })
                .then((res) => {
                    if (
                        !!res.data.data &&
                        res.data.hasOwnProperty("data") &&
                        res.data.data.hasOwnProperty("populars")
                    ) {
                        state.populars = res.data.data.populars;
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        };

        const clearSearch = () => {
            state.search = null;
            state.results = {};
        };
        const blurHandler = () =>
            setTimeout(() => {
                state.focus = false;
                vd.value.search.$touch();
            }, 250);
        const shouldSubmit = computed(() => {
            return !state.submitting && !vd.value.$invalid ? true : false;
        });
        fetchPopulars();
        return {
            props,
            state,
            vd,
            shouldSubmit,
            selectThemeOverrides,
            inputThemeOverrides,
            buttonThemeOverrides,
            typographyThemeOverrides,
            railStyle,
            asset,
            searchResults,
            populars,
            updateSearch,
            fetchResults,
            clearSearch,
            blurHandler,
            getServiceProfileLink,
            getPackageProfileLink,
            getItemProfileLink,
        };
    },
});
