import { defineComponent } from "vue";
import Search from "./components/Search/View.vue";

export default defineComponent({
    name: "SearchRoot",
    components: {
        Search,
    },
    setup() {
        return {};
    },
});
